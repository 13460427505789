<template>
    <div class="audit-list"
         ref="listBox">
        <a-table :columns="columns"
                 :data-source="list"
                 :pagination="false"
                 :loading="loading"
                 :scroll="scroll"
                 :row-key="record => record.id">
            <div slot="action"
                 slot-scope="record">
                <a-button class="btn"
                          type="primary"
                          :disabled="record.status > 2"
                          @click="handleExamine(record)">审核</a-button>
            </div>
        </a-table>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('financeManage');

export default {
    name: "AuditList", // 设备列表
    props: {
        list: {
            type: Array,
            default () {
                return [];
            },
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            columns: [
                {
                    title: "商家账号",
                    dataIndex: "username",
                    key: "username",
                    width: 200,
                    fixed: "left",
                },
                {
                    title: "商家名称",
                    dataIndex: "name",
                    key: "name",
                    width: 200
                },
                {
                    title: "提现金额（元）",
                    dataIndex: "money",
                    key: "money",
                    width: 200,
                },
                {
                    title: "申请时间",
                    dataIndex: "createTime",
                    key: "createTime",
                    width: 200,
                },
                {
                    title: "状态",
                    dataIndex: "auditStatus",
                    key: "auditStatus",
                    width: 200,
                },
                {
                    title: "操作",
                    key: "operation",
                    fixed: "right",
                    width: 150,
                    align: "center",
                    scopedSlots: { customRender: "action" },
                },
            ],
            scroll: {
                x: true,
                y: 480,
            },
        };
    },
    methods: {
        // -----------------事件处理函数----------------
        ...mapActions([ 'setAuditInfoAsync' ]),

        // 审核
        handleExamine (value) {
            this.setAuditInfoAsync(value)
            this.$emit("onExamine");
        }
    },
};
</script>

<style lang="less" scoped>
.audit-list {
    width: 100%;
    // height: 100%;
    overflow: auto;
}
</style>