<template>
    <div class="audit-search-wrapper">
        <a-form class="search-wrapper"
                layout="inline"
                :form="form"
                @submit="handleSearch">

            <!-- 商家账号 -->
            <a-form-item label="商家账号"
                         class="form-item-wrapper"
                         :label-col="formName.labelCol"
                         :wrapper-col="formName.wrapperCol">

                <a-input v-decorator="['username', { initialValue: '' }]"
                         placeholder="按商家账号搜索" />

            </a-form-item>

            <!-- 商家名称 -->
            <a-form-item label="商家名称"
                         class="form-item-wrapper"
                         :label-col="formName.labelCol"
                         :wrapper-col="formName.wrapperCol">

                <a-input v-decorator="['name', { initialValue: '' }]"
                         placeholder="按商家名称搜索" />

            </a-form-item>

            <!-- 申请时间 -->
            <a-form-item label="申请时间"
                         class="form-item-wrapper form-time"
                         :label-col="formTime.labelCol"
                         :wrapper-col="formTime.wrapperCol">

                <a-range-picker v-decorator="['time']"
                                :show-time="{ format: 'HH:mm:ss' }"
                                format="YYYY-MM-DD HH:mm:ss"
                                :placeholder="['开始日期', '结束日期']" />

            </a-form-item>

            <!-- 状态 -->
            <a-form-item label="状态"
                         class="form-item-wrapper status-form"
                         :label-col="formStatus.labelCol"
                         :wrapper-col="formStatus.wrapperCol">

                <a-select v-decorator="['status', { initialValue: '' }]">

                    <template v-for="item in status">
                        <a-select-option :value="item.value"
                                         :key="item.value">
                            {{ item.name }}
                        </a-select-option>
                    </template>

                </a-select>

            </a-form-item>

            <!-- 查询/重置 -->
            <a-form-item class="form-item-wrapper">
                <a-button class="submit-btn"
                          type="primary"
                          html-type="submit">
                    查询
                </a-button>

                <a-button @click="resetForm">
                    重置
                </a-button>
            </a-form-item>

        </a-form>
    </div>
</template>

<script>
export default {
    name: 'AuditSearch', // 商家用户筛选
    data () {
        return {
            formName: {
                labelCol: { span: 7 },
                wrapperCol: { span: 14 },
            },
            formStatus: {
                labelCol: { span: 7 },
                wrapperCol: { span: 14 },
            },
            formTime: {
                labelCol: { span: 5 },
                wrapperCol: { span: 14 },
            },
            form: this.$form.createForm(this, { name: 'auditSearch' }),

            status: [
                {
                    name: '全部',
                    value: ''
                },
                {
                    name: '未审核',
                    value: '0'
                },
                {
                    name: '审核通过',
                    value: '1'
                },
                {
                    name: '审核不通过',
                    value: '2'
                },
                {
                    name: '提现成功',
                    value: '3'
                },
                {
                    name: '提现失败',
                    value: '4'
                }
            ]
        }
    },
    methods: {
        // 点击查询
        handleSearch (e) {
            e.preventDefault();
            const values = this.form.getFieldsValue();

            this.$emit('onSearch', values)
        },
        // 重置
        resetForm () {
            this.form.resetFields()

            const values = this.form.getFieldsValue();

            this.$emit('onSearch', values)
        }
    }
}
</script>

<style lang="less" scoped>
.audit-search-wrapper {
    width: 100%;

    .search-wrapper {
        width: 80%;

        .form-item-wrapper {
            width: 300px;
            // margin-bottom: 20px;

            .submit-btn {
                margin: 0 20px;
            }
        }

        .form-time {
            width: 450px;
        }
    }
}
</style>