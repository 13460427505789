import api from './api'
import { request } from './request'

// 获取提现申请列表
function getAuditList(pageNumber, pageSize, params = {
    endTime: '',
    startTime: '',
    status: '',
    operatorName: '',
    userName: ''
}) {
    return request({
        url: api.financeManage.list,
        method: 'post',
        data: {
            pageNumber, pageSize, params
        }
    })
}

// 审核
function examineAudit(id, status) {
    return request({
        url: api.financeManage.examine,
        method: 'post',
        data: { id, status }
    })
}

export {
    getAuditList,
    examineAudit
}