<template>
    <div id="withdrawal-audit">
        <!-- 顶部面包屑 -->
        <breadcrumb :routes="breadcrumb" />

        <!-- 数据内容 -->
        <div class="withdrawal-audit-data">
            <!-- 筛选 -->
            <audit-search @onSearch="onSearch" />

            <!-- 列表 -->
            <div class="audit-list-wrapper">
                <audit-list :list="auditList"
                            :loading="loading"
                            @onExamine="showModal = true" />
            </div>

            <!-- 分页 -->
            <pagination :page.sync="page"
                        :page-size="pageSize"
                        :total-size="totalSize"
                        @onPageChange="onPageChange"
                        @onSizeChange="onSizeChange" />
        </div>

        <!-- 审核 -->
        <examine-pop :show.sync="showModal" @onSuccess="onSuccess" />
    </div>
</template>

<script>
import AuditSearch from './childComps//AuditSearch'
import AuditList from './childComps/AuditList'
import ExaminePop from './childComps/ExaminePop'

import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import Pagination from 'components/pagination/Pagination'

import { getAuditList } from 'network/financeManage'

export default {
    name: 'WithdrawalAudit', // 提现申请
    components: {
        AuditSearch,
        AuditList,
        ExaminePop,

        Breadcrumb,
        Pagination
    },
    data () {
        return {
            breadcrumb: [
                {
                    path: '',
                    breadcrumbName: "首页",
                },
                {
                    path: "/withdrawalAudit",
                    breadcrumbName: "提现申请",
                },
            ],

            username: '',
            name: '',
            status: '',
            startTime: '',
            endTime: '',

            page: 1,
            pageSize: 10,
            totalSize: 0,
            loading: false,

            auditList: [],

            showModal: false, // 是否显示审核弹窗
        }
    },
    created () {
        this._getAuditList(this.page, this.pageSize)
    },
    methods: {
        // -------------------事件处理函数-------------------
        // 查询
        onSearch (values) {
            this.status = values.status || '';
            this.username = values.username || '';
            this.name = values.name || '';

            if (values.time) {
                this.startTime = values.time[0].format('YYYY-MM-DD HH:mm:ss');
                this.endTime = values.time[1].format('YYYY-MM-DD HH:mm:ss');
            } else {
                this.startTime = ''
                this.endTime = ''
            }

            let params = {
                endTime: this.endTime,
                startTime: this.startTime,
                status: this.status,
                operatorName: this.name,
                userName: this.username
            }

            this.page = 1;

            this._getAuditList(this.page, this.pageSize, params)
        },
        // 切换页码
        onPageChange (page) {
            this.page = page;

            let params = {
                endTime: this.endTime,
                startTime: this.startTime,
                status: this.status,
                operatorName: this.name,
                userName: this.username
            }

            this._getAuditList(this.page, this.pageSize, params)
        },

        // 切换一页多少条
        onSizeChange (pageSize) {
            this.pageSize = pageSize;

            let params = {
                endTime: this.endTime,
                startTime: this.startTime,
                status: this.status,
                operatorName: this.name,
                userName: this.username
            }

            this._getAuditList(this.page, this.pageSize, params)
        },

        // 成功
        onSuccess () {
            let params = {
                endTime: this.endTime,
                startTime: this.startTime,
                status: this.status,
                operatorName: this.name,
                userName: this.username
            }

            this._getAuditList(this.page, this.pageSize, params)
        },

        // ---------------------网络请求-------------------
        _getAuditList (pageNumber, pageSize, params = {
            endTime: '',
            startTime: '',
            status: '',
            operatorName: '',
            userName: ''
        }) {
            this.loading = true;

            getAuditList(pageNumber, pageSize, params).then(res => {
                this.totalSize = res.totalSize;
                this.page = res.currentSize > 0 ? pageNumber : this.page;

                const list = res.data;

                let newList = [];

                class Audit {
                    constructor(obj) {
                        for (const key in obj) {
                            this[key] = obj[key];
                        }
                    }
                }

                for (let i = 0; i < list.length; i++) {
                    let item = list[i];

                    let auditStatus = ''
                    switch (item.status) {
                        case 0:
                            auditStatus = '未审核';
                            break;
                        case 1:
                            auditStatus = '审核通过';
                            break;
                        case 2:
                            auditStatus = '审核不通过';
                            break;
                        case 3:
                            auditStatus = '提现成功';
                            break;
                        case 4:
                            auditStatus = '提现失败';
                            break;
                    }


                    let audit = new Audit({
                        username: item.userName,
                        name: item.realName,
                        money: item.operationAmount,
                        auditStatus,
                        ...item
                    })

                    for (const key in audit) {
                        !audit[key] && audit[key] != 0 && (audit[key] = '暂无数据')
                    }

                    newList.push(audit)
                }

                this.loading = false;
                this.auditList = newList;
            }).catch(err => {
                this.loading = false;
            })
        }
    }
}
</script>

<style lang="less" scoped>
#withdrawal-audit {
    width: 100%;
    position: relative;

    .withdrawal-audit-data {
        margin: 0 20px 20px;
        padding: 20px;
        background-color: #fff;
        border-radius: 5px;

        .audit-list-wrapper {
            width: 100%;
            margin: 20px auto;
            overflow: auto;
        }
    }
}
</style>