<template>
    <div class="examine-wrapper">
        <a-modal :visible="show"
                 title="审核"
                 on-ok="handleSubmit"
                 @cancel="handleCancel">
            <template slot="footer">
                <a-button :loading="loading"
                          @click="handleSubmit">确定</a-button>
                <a-button key="back"
                          @click="handleCancel">关闭</a-button>
            </template>

            <a-form v-bind="formItemLayout"
                    :form="form"
                    @submit="handleSubmit">

                <a-form-item label="用户">
                    <span>{{ auditInfo.operatorName || '无' }}</span>
                </a-form-item>

                <a-form-item label="提现金额">
                    <span>{{ auditInfo.operationAmount }}</span>
                </a-form-item>

                <a-form-item :label-col="formStatus.labelCol"
                             :wrapper-col="formStatus.wrapperCol">
                    <a-radio-group v-decorator="validate.status">
                        <a-radio value="1"> 审核通过 </a-radio>
                        <a-radio value="2"> 审核不通过 </a-radio>
                    </a-radio-group>
                </a-form-item>

            </a-form>
        </a-modal>
    </div>
</template>

<script>
import { examineAudit } from 'network/financeManage'

import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters } = createNamespacedHelpers('financeManage');

export default {
    name: "ExaminePop", // 商家账号创建/编辑
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            loading: false,

            form: this.$form.createForm(this, { name: "examine" }),

            formItemLayout: {
                labelCol: { span: 7 },
                wrapperCol: { span: 13 },
            },

            formStatus: {
                labelCol: { span: 1 },
                wrapperCol: { span: 13, offset: 7 },
            },

            validate: {
                status: [
                    "status",
                    {
                        initialValue: '1',
                        rules: [
                            {
                                required: true,
                            }
                        ]
                    },
                ],
            },
        };
    },

    computed: {
        ...mapGetters(["auditInfo"]),
    },

    methods: {
        // ----------------事件处理函数---------------
        ...mapActions([ 'setAuditInfoAsync' ]),

        handleCancel () {
            this.form.resetFields();

            this.setAuditInfoAsync({})

            this.$emit("update:show", false);
        },
        handleSubmit (e) {
            e.preventDefault();

            this.loading = true;

            this.form.validateFields((err, values) => {
                if (!err) {
                    let status = +values.status
                    this._examineAudit(this.auditInfo.id, status)
                } else {
                    this.loading = false;
                }
            });
        },

        // -----------------网络请求-----------------
        // 审核
        _examineAudit(id, status) {
            examineAudit(id, status).then(res => {
                this.$message.success(res.message);
                
                this.$emit('onSuccess')
                this.handleCancel()
                this.loading = false;
            }).catch(err => {
                this.loading = false
            })
        }
    },
};
</script>

<style lang="less" scoped>
</style>